import React, { useEffect } from 'react';

export default function GladlySidekick() {   
    const query = new URLSearchParams(window.location.search);
    const geo = query.get('geo');

    const sidekickId = () => {
        switch ((geo ?? '').toLowerCase()) {
            case 'bh_au':
                return 'balsambrands-uat.com-au-OPT';
            case 'bh_de':
                return 'balsambrands-uat.com-de-OPT';
            case 'bh_fr':
                return 'balsambrands-uat.com-fr-OPT';
            case 'bh_uk':
                return 'balsambrands-uat.com-uk-OPT';
            default:
                return 'balsambrands-uat.com-us-OPT';
        }
    }

    useEffect(() => {
        window.gladlyConfig = {
            appId: sidekickId()
        };
    });

    return (
        <>
        </>
    );
}
